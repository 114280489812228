'use strict';

var raf = require('raf');
var calendar = require('./calendar');

function inline (elem, calendarOptions) {
  var o = calendarOptions || {};

  o.appendTo = elem;

  var api = calendar(o)
    .on('ready', ready);

  function ready () {
    raf(api.show);
  }

  return api;
}

module.exports = inline;
